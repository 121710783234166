<template>
  <div class="w-full">
    <div class="p-5">
      <table class="table-auto w-full">
        <thead>
          <tr class="border">
            <th class="text-left pl-2">CODE</th>
            <th class="text-center">Cloud</th>
            <th class="text-center">Cron</th>
            <th class="text-center">Pipelines</th>
            <th class="text-center">Database</th>
            <th class="text-center">Storage</th>
            <th class="text-center">Backup</th>
          </tr>
        </thead>
        <tbody class="border-r border-l border-b">
          <tr v-for="a in applications" :key="a.code">
            <td class="px-2">{{ a.code }}</td>
            <td>
              <CloudIcon class="h-8 mx-auto" v-if="a.image == 1" />
            </td>
            <td class="text-center">
              <ClockIcon class="h-8 mx-auto" v-if="a.cron == 1" />
            </td>
            <td class="text-center">
              <CheckIcon class="h-8 mx-auto" v-if="a.devops == 1" />
            </td>
            <td class="text-center">
              <DatabaseIcon class="h-8 mx-auto" v-if="a.database == 1" />
            </td>
            <td class="text-center">
              <DocumentTextIcon class="h-8 mx-auto" v-if="a.storage == 1" />
            </td>
            <td class="text-center">
              <ArchiveIcon class="h-8 mx-auto" v-if="a.backup == 1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  CheckIcon,
  ClockIcon,
  XIcon,
  CloudIcon,
  DatabaseIcon,
  DocumentTextIcon,
  ArchiveIcon,
} from "@heroicons/vue/outline";

export default {
  components: {
    CheckIcon,
    CloudIcon,
    ClockIcon,
    DatabaseIcon,
    DocumentTextIcon,
    ArchiveIcon,
  },
  data() {
    return {
      applications: [
        {
          code: "REGEN DOLIBARR for demo",
          image: 1,
          cron: 1,
          database: 1,
          storage: 1,
        },
        { code: "MOSAPIC", image: 1, devops: 1, storage: 1, backup: 1 },
        { code: "ORONUI DEV Website", image: 1, devops: 1 },
        {
          code: "FULL BO/BO FOUR",
          image: 1,
          devops: 1,
          database: 1,
          backup: 1,
        },
      ],
    };
  },
  iconChecked(value) {
    return value == 1 ? CheckIcon : XIcon;
  },
};
</script>
