<template>
  <div class="h-screen">
    <div class="flex flex-col h-full">
      <h1
        class="bg-gray-100 w-full text-6xl font-bold text-center p-2 shadow-md shadow-slate-150 text-gray-500"
      >
        - ORONUI DEV -
      </h1>
      <div
        :class="{
          flex: true,
          ' flex-col': isVertical,
          ' flex-row': !isVertical,
        }"
      >
        <LeftPart class="hidden" />
        <RightPart class="hidden" />

      </div>

      <div class="absolute w-full text-center bottom-0">
        <AppVersion />
      </div>
    </div>
  </div>
</template>

<script>
import AppVersion from "./AppVersion.vue";
import LeftPart from "./body/LeftPart.vue";
import RightPart from "./body/RightPart.vue";
export default {
  components: {
    AppVersion,
    LeftPart,
    RightPart,
  },
  data() {
    return {
      isVertical: false,
    };
  },
  created() {
    if (screen.availHeight > screen.availWidth) {
      this.isVertical = true;
    }
  },
};
</script>
