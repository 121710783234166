import { createApp } from "vue";
import { createPlausible } from "@huntersofbook/plausible-vue";

import App from "./App.vue";
import "./assets/index.css";

const plausible = createPlausible({
  init: {
    domain: "oronui.dev",
    apiHost: "https://stats-v1.scenophoto.fr",
    trackLocalhost: true,
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: true,
  },
  partytown: false,
});

const app = createApp(App);
app.use(plausible);
app.mount("#app");
