<template>
  <div class="w-full h-full top-0 bottom-0">
    <div class="p-5">What we do</div>
    <div class="w-96 h-64 ml-5 bg-gray-100 relative">
      <img class="absolute" alt="PLAN" src="../../assets/img/Arrow8.svg" />
      <img class="absolute" alt="CODE" src="../../assets/img/Arrow5.svg" />
      <img class="absolute" alt="BUILD" src="../../assets/img/Arrow7.svg" />
      <img class="absolute z-10" alt="TEST" src="../../assets/img/Arrow6.svg" />

      <img class="absolute" alt="RELEASE" src="../../assets/img/Arrow2.svg" />
      <img class="absolute" alt="DEPLOY" src="../../assets/img/Arrow4.svg" />
      <img class="absolute" alt="OPERATE" src="../../assets/img/Arrow1.svg" />
      <img class="absolute" alt="MONITOR" src="../../assets/img/Arrow3.svg" />
    </div>
  </div>
</template>

<script>
export default {};
</script>
